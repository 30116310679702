body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'Roboto', sans-serif; /* Modern, clean font */
  background-color: #f5f5f5; /* Lighter background */
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
  background-color: #f0f0f0;
  padding: 1rem;
  box-sizing: border-box;
}

h1 {
  font-size: 2.5rem;
  color: #2c3e50; /* Darker, more modern color */
  margin-bottom: 0.5rem;
  font-weight: 700; /* Bolder font weight */
  letter-spacing: -1px; /* Tighter letter spacing */
}

.score {
  font-size: 1.5rem;
  color: #34495e;
  margin-bottom: 0.5rem;
  font-weight: 500;
}

.game-board {
  position: relative;
  width: calc(100vw - 2rem);
  height: calc(100vw - 2rem);
  max-width: calc(100vh - 10rem);
  max-height: calc(100vh - 10rem);
  margin: 0 auto;
  border: none; /* Remove border */
  border-radius: 12px; /* Rounded corners */
  box-shadow: 0 10px 30px rgba(0,0,0,0.1); /* Softer shadow */
  background-color: #ecf0f1; /* Slightly off-white background */
  overflow: hidden; /* Change back to hidden */
}

@media (min-aspect-ratio: 1/1) {
  .game-board {
    width: calc(100vh - 10rem);
    height: calc(100vh - 10rem);
  }
}

.snake-segment {
  position: absolute;
  background-color: #4CAF50;
  border-radius: 20%;
}

.snake-segment.wrapping {
  transition: none;
}

.snake-head {
  background-color: #388E3C;
  border-radius: 30%;
}

.snake-eye {
  position: absolute;
  width: 20%;
  height: 20%;
  background-color: #FFFFFF;
  border-radius: 50%;
  top: 20%;
}

.snake-eye::after {
  content: '';
  position: absolute;
  width: 50%;
  height: 50%;
  background-color: #FF0000;
  border-radius: 50%;
  top: 25%;
  left: 25%;
}

.snake-eye-left {
  left: 20%;
}

.snake-eye-right {
  right: 20%;
}

.snake-body {
  background-color: #4CAF50; /* Slightly lighter green for body */
}

.snake-tail {
  background-color: #81C784; /* Lightest green for tail */
  border-radius: 40% 40% 50% 50%; /* More tapered shape for the tail */
}

.food {
  position: absolute;
  background-color: #FF5722;
  border-radius: 50%;
}

@keyframes pulse {
  from { transform: scale(1); }
  to { transform: scale(1.1); }
}

.game-over {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.9);
  padding: 20px;
  border-radius: 10px;
  text-align: center;
}

.game-over h2 {
  font-size: 2.5rem;
  color: #2c3e50;
  margin-bottom: 1rem;
  font-weight: 700;
}

.game-over button {
  background-color: #3498db;
  border: none;
  color: white;
  padding: 12px 24px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1rem;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 6px;
  transition: all 0.3s ease;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.game-over button:hover {
  background-color: #2980b9;
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(0,0,0,0.1);
}

.button-container {
  margin-top: 10px;
}

.button-container button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.button-container button:hover {
  background-color: #45a049;
}

.game-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.game-info button {
  padding: 5px 10px;
  font-size: 14px;
  cursor: pointer;
}

/* Add these new rules */
.snake-segment-transition-left {
  transform: translateX(-100%);
}

.snake-segment-transition-right {
  transform: translateX(100%);
}

.snake-segment-transition-up {
  transform: translateY(-100%);
}

.snake-segment-transition-down {
  transform: translateY(100%);
}